import 'lazysizes'
import 'babel-polyfill'
import 'js/polyfills/customEvent'
import 'js/polyfills/closest'

// =utils
import 'js/utils/breakpoints'
import 'js/utils/detectTouch'
import 'js/utils/doc-height'

if (document.querySelector('[data-height="equal"]')) {
  import(/* webpackChunkName: "utilEqualHeight" */ 'js/utils/equalHeight')
}

if (document.querySelector('.js-button')) {
  import(/* webpackChunkName: "utilButtonDecoration" */ 'js/utils/buttonDecoration')
}

// debug mode
if (document.querySelector('body').classList.contains('-debug')) {
  import(/* webpackChunkName: "debugger" */ 'js/utils/debugger')
}

//===============================================================/
//  =components
//===============================================================/

if (document.querySelector('.js-login')) {
  import(/* webpackChunkName: "componentLogin" */ 'js/components/login')
}

//===============================================================/
//  =blocks
//===============================================================/

if (document.querySelector('.js-masonry-grid')) {
  import(/* webpackChunkName: "masonryBlock" */ 'js/blocks/masonry-block')
}

//===============================================================/
//  =layout
//===============================================================/

if (document.querySelector('.js-header-nav')) {
  import(/* webpackChunkName: "headerNav" */ 'js/layouts/nav')
}

//===============================================================/
//  =pages
//===============================================================/

//===============================================================/
//  =animations
//===============================================================/

import(/* webpackChunkName: "scrollTriggerReset" */ 'js/animations/scroll-trigger-reset')

if (document.querySelector('.js-contact-bar')) {
  import(/* webpackChunkName: "contactBar" */ 'js/animations/components/contact-bar')
}

if (document.querySelector('.js-animate-tiles')) {
  import(/* webpackChunkName: "animateTiles" */ 'js/animations/components/animate-tiles')
}

if (document.querySelector('.js-scroll-control-videos')) {
  import(/* webpackChunkName: "scrollControlVideos" */ 'js/animations/components/scroll-control-videos')
}

if (document.querySelector('.js-general-content-pinned')) {
  import(/* webpackChunkName: "generalContent" */ 'js/animations/blocks/general-content')
}

if (document.querySelector('.js-animate-footer')) {
  import(/* webpackChunkName: "animateFooter" */ 'js/animations/Layouts/animate-footer')
}
